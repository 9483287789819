import { FC, useRef, useCallback } from 'react'
import { useSelector } from '@xstate/react'

import {
  DraggableSyntheticListeners,
} from '@dnd-kit/core'


import {
  AnimateLayoutChanges,
  useSortable,
  defaultAnimateLayoutChanges,
} from '@dnd-kit/sortable'

import { TrackMapItem } from './state/types'
import { MicService } from './state/mic.machine'
import { VolFader, Pan, Eq } from './faders'
import { TrackShell } from './shell'
import { ToggleHeadset, ToggleBroadcast } from './buttons'
import { DragHandle, ChevronLeft, ChevronRight, Clear } from './icons'
import { AudioConstraints, ExtendedDeviceInfo } from './state/types'

const hasSetting = (device: ExtendedDeviceInfo, label: string) => (device?.settings || {}).hasOwnProperty(label)
// firefox cannot call getCapabilities()
// const hasCap = (device: ExtendedDeviceInfo, label:string) => (device?.capabilities || {}).hasOwnProperty(label)

type SettingsBoolProp = {
  doShow: boolean
  label: string
  field: string
  enabled: boolean
  handleDeviceChange: (val: AudioConstraints) => void
}

const BoolSetting: FC<SettingsBoolProp> = ({
  doShow,
  label,
  field,
  enabled,
  handleDeviceChange
}: SettingsBoolProp) => {
  const handleClick: React.ChangeEventHandler = (e) => {
    handleDeviceChange({ [field]: !enabled })
    e.stopPropagation()
  }

  return doShow
    ? <div className='flex flex-row gap-4 place-items-center justify-end  ' >
      <div >{label}</div>
      <div>
        <input type="checkbox" className="toggle" checked={enabled} onChange={handleClick} />
      </div>
    </div>
    : null
}

const getDeviceAudioSettings = (device: ExtendedDeviceInfo) => [
  device?.settings?.sampleRate,
  device?.settings?.sampleSize,
  device?.settings?.channelCount === 1
    ? 'mono'
    : device?.settings?.channelCount === 2
      ? 'stereo'
      : device?.settings?.channelCount,
  device?.settings?.hasOwnProperty('latency')
    // @ts-ignore
    ? 'latency: ' + device.settings['latency'] + ''
    : null
]
  .filter(s => s && String(s).length)
  .join(' / ')

export type ChangeDeviceArgs = {
  deviceId: string
  constraints?: AudioConstraints
}
type DeviceLabelProps = {
  isSelected: boolean
  device: ExtendedDeviceInfo
  changeDevice: (s: ChangeDeviceArgs) => void
}
const DeviceLabel: FC<DeviceLabelProps> = ({
  isSelected,
  device,
  changeDevice,
}: DeviceLabelProps) => {
  const handleDeviceChange = (val: AudioConstraints) => {
    const constraints = Object.assign({}, {
      echoCancellation: device?.settings?.echoCancellation || false,
      noiseSuppression: device?.settings?.noiseSuppression || false,
      autoGainControl: device?.settings?.autoGainControl || false
    }, val)
    console.log('constraints', constraints)
    changeDevice({ deviceId: device.deviceId, constraints })
  }
  const changeInput = () => {
    if (isSelected) return false
    // we open without constraints so as to get the defaults.
    // this seems to work best for all devices.  Chrome on Android is the trickiest.
    changeDevice({ deviceId: device.deviceId })
  }

  return (
    <div
      className={'p-4 w-full rounded break-all cursor-pointer text-mic-con ' + (isSelected ? ' bg-base-300' : ' bg-base-100')}
      onClick={changeInput}
    >
      <div className='flex flex-col gap-2 justify-center '>
        <div className=''>
          <div className=''>{device.label || 'unknown device'}</div>
          <div className='text-sm md:text-md'>{getDeviceAudioSettings(device)}</div>
        </div>
        <BoolSetting
          doShow={isSelected && hasSetting(device, 'echoCancellation')}
          field='echoCancellation'
          label='Echo Cancellation'
          enabled={!!(device?.settings?.echoCancellation)}
          handleDeviceChange={handleDeviceChange}
        />
        <BoolSetting
          doShow={isSelected && hasSetting(device, 'noiseSuppression')}
          field='noiseSuppression'
          label='Noise Suppression'
          enabled={!!(device?.settings?.noiseSuppression)}
          handleDeviceChange={handleDeviceChange}
        />
        <BoolSetting
          doShow={isSelected && hasSetting(device, 'autoGainControl')}
          field='autoGainControl'
          label='Auto Gain'
          enabled={!!(device?.settings?.autoGainControl)}
          handleDeviceChange={handleDeviceChange}
        />
      </div>
    </div>
  )
}
type DeviceSelectProps = {
  devices: Map<string, ExtendedDeviceInfo>,
  device?: ExtendedDeviceInfo,
  changeDevice: (s: ChangeDeviceArgs) => void
}
export const DeviceSelect: FC<DeviceSelectProps> = ({
  devices,
  device,
  changeDevice,
}) => {
  return (
    <div className='flex-grow flex flex-col w-full h-auto sm:h-full gap-1 '>
      {
        devices && devices.size
          ? Array.from(devices).map(([gid, d], idx) => (
            <DeviceLabel
              isSelected={d.deviceId === device?.deviceId}
              key={gid + '-' + idx}
              device={d}
              changeDevice={changeDevice}
            />
          ))
          : <div className="p-4 text-error-con">You have no devices. Please very that you allow the microphone.
            <div className="text-sm p-4">In firefox and chrome, click the filter or mic icon to the left of the URL bar. On safari, you may need to refresh the page and click &rdquo;allow&ldquo; mic permissions when prompted.
            </div>
          </div>
      }
    </div>
  )
}
type TopBarProps = {
  service: MicService
}
const TopBar: FC<TopBarProps> = ({
  service
}) => {

  const kill = () => service.send({ type: 'DONE' })
  const head = useSelector(service, (s) => s.context.master)
  const broad = useSelector(service, (s) => s.context.broadcast)

  const toggleHead = () => service.send({ type: 'TOGGLE_M' })
  const toggleBroadcast = () => service.send({ type: 'TOGGLE_B' })


  return (
    <>
      <ToggleHeadset checked={!head} onChange={toggleHead} />
      <ToggleBroadcast checked={!broad} onChange={toggleBroadcast} />
      <div className='flex-grow' />
      <button className=" p-1" onClick={kill}><Clear size='20px' /></button>
    </>
  )
}

type MicBodyProps = {
  big: boolean
  service: MicService
  listeners: DraggableSyntheticListeners
}
const MicBody: FC<MicBodyProps> = ({
  big = false,
  service,
  listeners
}) => {
  const devices = useSelector(service, (s) => s.context.devices) // map
  const deviceId = useSelector(service, (s) => s.context.deviceId)
  const changeDevice = useCallback(({ deviceId, constraints }: ChangeDeviceArgs) => {
    service.send({ type: 'GET_STREAM', deviceId, constraints })
  }, [service])

  const toggleBig = () => service.send({ type: 'TOGGLE_BIG' })

  return (
    <>
      <div
        className="flex-none text-xs text-nowrap w-full mx-1 overflow-hidden"
        style={big ? {} : { width: '95%' }}
      >
        {devices.get(deviceId || '')?.label || 'none'}
      </div>
      <div className='h-10 bg-base-200 flex-grow flex flex-row gap-4 rounded m-1 lg:m-2 p-1 lg:p-2 relative'>
        <div className="w-8 h-full flex flex-col gap-2" >
          <label className="swap swap-rotate" >
            <input type="checkbox" onChange={toggleBig} checked={big} />
            <ChevronLeft size='36px' className="swap-on" />
            <ChevronRight size='36px' className="swap-off" />
          </label>
          <VolFader service={service} />
        </div>
        <div className={'flex w-full h-full ' + (big ? 'flex-col' : 'flex-row')}>
          <div className={'flex gap-4 w-full ' + (big ? 'flex-row flex-none h-12' : 'flex-col place-items-center flex-shrink')}>
            <div className={big ? "flex-grow" : "hidden"} />
            <Pan service={service} />
            <div className={big ? "flex-grow" : "flex-none h-1"} />
            <div className={'flex gap-4 ' + (big ? 'flex-row flex-none h-12' : 'flex-col flex-col-reverse')}>
              <Eq kind='lo' key='eqlo' service={service} />
              <Eq kind='md' key='eqmd' service={service} />
              <Eq kind='hi' key='eqhi' service={service} />
            </div>
          </div>
          <ul className={'flex flex-col p-1 flex-grow gap-1 w-full rounded overflow-y-auto bg-base-100 flex transition-all duration-300 ' + (big ? 'opacity-100 scale-100' : 'opacity-0 scale-0 hidden')}>
            <DeviceSelect
              devices={devices}
              device={devices.get(deviceId || '')}
              changeDevice={changeDevice}
            />

          </ul>
        </div>
        <button
          disabled={big}
          className={"absolute -right-1 bottom-0 transition-all duration-300 " + (big ? 'opacity-0 scale-0' : 'opacity-100 scale-100')}
          {...listeners}
        >

          <DragHandle size='32px' />
        </button>
      </div>
    </>
  )
}

type MicProps = {
  item: TrackMapItem
  needsResume: boolean
}
/*
export const Mic: FC<MicProps> = ({
  item
}) => {
  const service = item.service as MicService
  const modalRef = useRef<HTMLDialogElement>(null)

  return (
    < div data-theme={'valentine'} className={
      ' bg-base-100 carousel-item carousel-center flex flex-col flex-none rounded select-none ' +
      ' transition-all ease-in-out duration-300 ' +
      (item.big ? "w-full sm:w-1/2 xl:w-1/3 h-full " : "w-32 h-full")
    }
    >
      <div className='w-full h-8 flex flex-row gap-1'>
        <TopBar key="topbar" service={service} />
      </div>
      <MicBody key="body" big={item.big} service={service} />
      <dialog ref={modalRef} className="modal w-screen h-screen" >
        <div className="modal-box w-11/12 max-w-5xl h-full bg-base flex flex-col">
          <form method="dialog" className="bg-red-100 relative">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          <div className='h-full w-full'>
            <h3 className="font-bold text-lg">Search</h3>
            ya</div>
        </div>
      </dialog>
    </div >
  )
}
*/

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })


export const MicB: FC<MicProps> = ({
  item,
}) => {
  const {
    isDragging,
    listeners,
    setNodeRef,
    transition = '',
    transform,
  } = useSortable({
    id: item.service.id,
    data: {
      type: 'container'
    },
    animateLayoutChanges,
  })


  const service = item.service as MicService
  // const onDelete = () => service.send({ type: 'DONE' })
  const onDropFiles = (files: FileList | File[]) => console.log('dropped files on mic', files)
  const onDropSignal = (over: boolean) => console.log('over mic', over)


  return (
    <TrackShell
      item={item}
      dataTheme="valentine"
      onDropFiles={onDropFiles}
      onDropSignal={onDropSignal}
      transition={transition}
      transform={transform}
      setNodeRef={setNodeRef}
      isDragging={isDragging}
    >
      <div className='w-full h-8 flex flex-row gap-1'>
        <TopBar key="topbar" service={service} />
      </div>
      <MicBody key="body" big={item.big} service={service} listeners={listeners} />

    </TrackShell>
  )
}
