import { FC, Dispatch, ReactNode, DragEventHandler, useRef } from 'react'
import { TrackMapItem } from './state/types'
import type { MainService } from './state/main.machine'
import {
  Transform,
  // Transition
} from '@dnd-kit/utilities'

import { CSS } from '@dnd-kit/utilities'


import { createPortal } from 'react-dom'

import { useSelector } from '@xstate/react'
import { Track } from './tracks'

import {
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
  useDroppable,
  closestCorners
} from '@dnd-kit/core'

import {
  arrayMove
} from '@dnd-kit/sortable'

import { Media } from './state/database'
import { Item } from './playlist'
import { PlayerService } from './state/player.machine'

import { coordinateGetter as multipleContainersCoordinateGetter } from './keyCoords'

import { MoveMediaArgs, DragInfo } from './state/types'



type TrackShellProps = {
  item: TrackMapItem
  color?: string
  dataTheme?: string
  // dragControls?: DragControls
  onDropFiles?: (files: FileList | File[]) => void
  onDropSignal?: (over: boolean) => void
  // setNodeRef: React.RefObject<HTMLDivElement>
  setNodeRef: (node: HTMLElement) => void
  isDragging: boolean
  transition: string & {}
  // transition: Pick<Transition, 'easing' | 'duration'>
  // transition: Transition
  // transition: { duration: number, ease: string}
  transform: Transform | null
  children: ReactNode
}

export const TrackShell: FC<TrackShellProps> = ({
  item,
  color,
  dataTheme,
  onDropFiles,
  onDropSignal,
  isDragging,
  setNodeRef,
  transform,
  transition,
  children
}) => {
  const onDrop: DragEventHandler<HTMLDivElement> = (ev) => {
    console.log('File(s) dropped', ev)
    ev.preventDefault()
    const files = ev.dataTransfer?.items?.length
      ? Array.from(ev.dataTransfer.items as DataTransferItemList)
        .filter(
          (i) =>
            i.kind === 'file' &&
            (i.type.indexOf('audio') !== -1 ||
              i.type.indexOf('video') !== -1)
        )
        .map((i) => i.getAsFile())
      : Array.from(ev.dataTransfer.files as FileList).filter(
        (i) =>
          i.type.indexOf('audio') !== -1 || i.type.indexOf('video') !== -1
      )


    if (files && files.length && onDropFiles) {
      // @ts-ignore
      onDropFiles(files)
    }
    return onDropSignal ? onDropSignal(false) : null
  }
  // const onDragOver = (ev) => ev.preventDefault()
  const onDragEnter: DragEventHandler<HTMLDivElement> = (ev) => {
    ev.preventDefault()
    return onDropSignal ? onDropSignal(true) : null
  }
  const onDragLeave = () => {
    return onDropSignal ? onDropSignal(false) : null
  }

  return (
    <div

      data-theme={dataTheme}
      // ref={isSortingTracks ? setNodeRef : undefined}
      // @ts-ignore
      ref={setNodeRef}
      onDragOver={onDragEnter}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragEnd={onDragLeave}
      onDrop={onDrop}
      style={{
        transition,
        transform: transform ? CSS.Translate.toString(transform) : undefined,
        opacity: isDragging ? 0.5 : undefined,
      }}
      className={
        color +
        ' carousel-item carousel-center flex flex-col flex-none rounded select-none ' +
        ' transition-all ease-in-out duration-300 ' +
        (isDragging ? '' : ' snap-center transition-all ease-in-out duration-500 ') +
        (item.big ? "w-full sm:w-1/2 xl:w-1/3 h-full " : "w-36 h-full")
      }
    >
      {children}
    </div>
  )
}


const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
}

type MainDropProps = {
  className?: string
  children: ReactNode
}

const MainDrop: FC<MainDropProps> = ({
  className,
  children
}) => {
  const {
    setNodeRef
  } = useDroppable({
    id: 'main'
  })

  return (
    <div
      ref={setNodeRef}
      className={className}
    >
      {children}
    </div>
  )
}
type AppShellProps = {
  className?: string
  mainService: MainService
  dragInfo: DragInfo
  setDragInfo: Dispatch<React.SetStateAction<DragInfo>>
  children: ReactNode
}
export const AppShell: FC<AppShellProps> = ({
  className = 'w-screen h-screen',
  mainService,
  dragInfo,
  setDragInfo,
  children
}) => {

  //const { mainService } = useContext(GlobalStateContext)

  const tracks = useSelector(mainService, (state) => state.context.tracks)
  const setTracksOrder = (tracksOrder: Array<string>) => mainService.send({ type: 'REORDER_TRACKS', tracksOrder })
  const tracksOrder = useSelector(mainService, (state) => state.context.tracksOrder)

  const moveMedia = (args: MoveMediaArgs) => mainService.send({ type: 'MOVE_MEDIA', ...args })

  // const big = useSelector(mainService, (state) => state.context.big)
  // const [activeId, setActiveId] = useState<string | null>(null)
  // const [dragInfo, setDragInfo] = useState<DragInfo>({ trackId: null, plItemId: null, media: null })

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: multipleContainersCoordinateGetter,
    })
  )

  const overInfo = useRef<DragInfo>({ trackId: null, plItemId: null, media: null })

  const onDragCancel = () => {
    setDragInfo({ trackId: null, plItemId: null, media: null })
  }

  const isSortingTracks: boolean = !!(dragInfo.trackId && !dragInfo.plItemId)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always
        }
      }}
      onDragStart={({ active }) => {
        if (active.data.current?.hasOwnProperty('trackId')) {
          const track = tracks.get(active.data.current?.trackId)
          if (!track?.service) return
          const snap = (track.service as PlayerService).getSnapshot()
          if (!snap) return
          const media = snap.context.playlistRef.getSnapshot().context.playlist.find((m: Media) => m.id === active.id)
          console.log('media', media)
          if (!media) return
          setDragInfo({
            trackId: active.data.current.trackId,
            plItemId: active.id as string,
            media
          })
          // console.log("--- onDragStart, playlistItem:", active.id, 'track:', active.data?.current?.trackId, 'media:', media )
        } else {
          // console.log("onDragStart, Track:", active.id )
          setDragInfo({
            trackId: active.id as string,
            plItemId: null,
            media: null
          })
        }
      }
      }
      onDragOver={({ active, over }) => {
        // console.log("\tonDragOver, ( trackId:", overInfo.current.trackId, ', plItemId:', overInfo.current.plItemId, ')')
        const overId = (over?.id || "") as string
        // we are dragging a track, let's move on
        if (dragInfo.plItemId === null || dragInfo.media === null) return
        // we are over the main or not over anything
        if (overId === 'main' || !overId || overId === '') return
        // moving a playlist item
        overInfo.current = tracks.get(overId)
          ? { trackId: overId, plItemId: overId === dragInfo.trackId ? overInfo.current.plItemId : null, media: null }
          : { trackId: over?.data?.current?.trackId || overInfo.current.trackId, plItemId: overId, media: null }

        // console.log("\tonDragOver, ( trackId:", overInfo.current.trackId, ', plItemId:', overInfo.current.plItemId, ')')


        if (dragInfo.trackId === overInfo.current.trackId) return

        const ddi = Object.assign({}, dragInfo, { media: Object.assign(dragInfo.media, { isQueued: false }) })
        // now we are switching an playlist item from one track to another

        // if we have both overInfo.trackId and overInfo.plItemId, it means we have gone over a new track at the place overInfo
        // a playlist item
        if (overInfo.current.trackId && overInfo.current.plItemId) {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
            over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          // console.log("\tonDragOver to new container", dragInfo, modifier, overInfo.current)
          moveMedia({
            dragInfo: ddi,
            modifier,
            overInfo: overInfo.current,
          })
        } else {
          // console.log("\tonDragOver to new container (no plItemId)", dragInfo, 0, overInfo.current)
          moveMedia({
            dragInfo: ddi,
            modifier: 0,
            overInfo: overInfo.current,
          })
        }
        setDragInfo((di: DragInfo) => Object.assign({}, di, {
          trackId: overInfo.current.trackId,
          media: ddi.media
        }))

      }}
      onDragEnd={({ active, over }) => {
        // console.log("--- onDragEnd, active: (", dragInfo.plItemId, ',trackId: ', dragInfo.trackId,
        // ") over: (", over?.id, ', trackId:', over?.data?.current?.trackId, ')')

        if (!dragInfo.trackId) return
        const overId = (over?.id || "") as string

        if (dragInfo.media === null) {
          if (overId !== '' && dragInfo.trackId !== overId) {
            const oldIndex = tracksOrder.indexOf(dragInfo.trackId)
            const newIndex = tracksOrder.indexOf(overId)
            const newTracks = arrayMove(tracksOrder, oldIndex, newIndex)
            onDragCancel()
            return setTracksOrder(newTracks)
          }
        }
        if (overInfo.current.trackId && overInfo.current.plItemId) {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
            over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          moveMedia({
            dragInfo,
            modifier,
            overInfo: overInfo.current,
          })
        } else {
          moveMedia({
            dragInfo,
            modifier: 0,
            overInfo: overInfo.current,
          })
        }

        overInfo.current = { trackId: null, plItemId: null, media: null }
      }}
      onDragCancel={onDragCancel}
    >

      <MainDrop className={className} >
        {children}
        {
          createPortal(
            <DragOverlay dropAnimation={dropAnimation} >
              {dragInfo.plItemId && dragInfo.media && dragInfo.trackId
                ? <Item media={dragInfo.media} service={tracks.get(dragInfo.trackId)?.service as PlayerService} />
                : dragInfo.trackId && tracks.get(dragInfo.trackId)
                  ? (
                    <div className='flex flex-row w-full select-none gap-1 md:gap-2 overflow-x-auto h-full '>
                      <Track
                        key={'track-' + dragInfo.trackId}
                        needsResume={false}
                        item={tracks.get(dragInfo.trackId)}
                        isSortingTracks={isSortingTracks}
                      />
                    </div>
                  )
                  : null
              }
            </DragOverlay>
            , document.body)
        }
      </MainDrop>
    </DndContext>
  )
}

