import { setup, assign, cancel, sendTo, fromCallback, assertEvent, enqueueActions, stopChild } from 'xstate'
import type { ActorRefFrom } from 'xstate'
import adapter from 'webrtc-adapter'

import { WebsocketMachine } from './websocket.machine'

import { SetRemoteStream } from './rtc.machine'
import { createPlayerInput, playerMachine } from './player.machine'
import { createOscContext, oscMachine } from './osc.machine'
import { createMicContext, micMachine } from './mic.machine'
import { AnnounceDlKeys, DownloadMachine } from './download.machine'
import { TrackMapItem, TrackInfo, MoveMediaArgs } from './types'
import { generateId, getProcessWs } from './utils'

export type TracksContext = {
  browserVersion: string
  wsurl: string

  ctx: AudioContext
  needsResume: boolean
  big: boolean
  bcst: {
    dest: MediaStreamAudioDestinationNode
    gain: GainNode
  }
  local: {
    gain: GainNode
  },
  remote: {
    gain: GainNode
    analyser: AnalyserNode
    source?: MediaStreamAudioSourceNode
  },

  tracksOrder: Array<string>
  tracks: Map<string, TrackMapItem>
  tracksInfo: Map<string, TrackInfo>
}


export type MoveMediaEvent = { type: 'MOVE_MEDIA' } & MoveMediaArgs
type PersistTracks = { type: 'PERSIST_TRACKS', data: Array<TrackInfo> }
type LoadTracks = { type: 'LOAD_TRACKS', tracks: Array<TrackInfo> }
type RemoveTrack = { type: 'REMOVE_TRACK', id: string }
type ReorderTracks = { type: 'REORDER_TRACKS', tracksOrder: Array<string> }
type SendTrackInfo = { type: 'SEND_TRACK_INFO', id: string, track: TrackInfo }
type ToggleBig = { type: 'TOGGLE_BIG', id: string }
type SetRemoteGain = { type: 'SET_REMOTE_GAIN', vol: number }
type SetNeedsResume = { type: 'SET_NEEDS_RESUME' }
type TryResume = { type: 'TRY_RESUME', ctx: AudioContext }
export type TracksEvents = { type: 'ADD_OSC' }
  | { type: 'ADD_MIC' }
  | { type: 'ADD_PLAYER' }
  | { type: 'RESUME' }
  | TryResume
  | SetNeedsResume
  | SetRemoteStream // { type: 'SET_REMOTE_STREAM', remoteStream: MediaStream }
  | SetRemoteGain
  | ToggleBig
  | SendTrackInfo
  | ReorderTracks
  | RemoveTrack
  | LoadTracks
  | PersistTracks
  | MoveMediaEvent
  | AnnounceDlKeys // comes from dlMachine and we forward to track

const setRemoteStreamSource = ({ context, event }: { context: TracksContext, event: SetRemoteStream }) => {
  console.log('analyser setting up remote stream source')
  // we set the stream source here AND we also add an <audio muted=true /> element in <Broadcast />
  // otherwise, chrome won't play the audio
  // supposedly, if we add the streamsource node, it helps chrome with echo cancellation
  if (context.remote.source) {
    context.remote.source.disconnect()
  }
  context.remote.source = context.ctx.createMediaStreamSource(event.remoteStream)
  context.remote.source
    .connect(context.remote.gain)
  context.remote.source
    .connect(context.remote.analyser)

  // gain is already connected to the analyser -> ctx.destination

}

const initContext = (): TracksContext => {
  const ctx = new window.AudioContext({ latencyHint: 0 })
  const bcst = {
    dest: ctx.createMediaStreamDestination(),
    gain: ctx.createGain()
  }
  const local = {
    gain: ctx.createGain()
  }
  const remote = {
    gain: ctx.createGain(),
    analyser: ctx.createAnalyser()
  }
  local.gain.connect(ctx.destination)
  bcst.gain.connect(bcst.dest)

  remote.gain
    .connect(ctx.destination)

  remote.analyser.smoothingTimeConstant = 0.3
  remote.analyser.fftSize = 1024
  remote.gain.gain.value = 1
  // we create and keep an osc running at zero volume.
  // if we don't, the RTC think we are still 'talking' when
  // we have tracks that were playing and then remove everything.
  const osc = ctx.createOscillator()
  const gain = ctx.createGain()
  gain.gain.value = 0
  osc.connect(gain).connect(local.gain)
  osc.connect(gain).connect(bcst.gain)
  osc.connect(gain).connect(remote.gain)
  osc.start(0)
  return ({
    browserVersion: adapter.browserDetails.browser + ' | ' + adapter.browserDetails.version,

    // record
    // processInfo: {},
    // recordingInfo: {},

    // Tracks Context
    big: false,
    ctx,
    bcst,
    local,
    remote,
    tracksOrder: [],
    tracksInfo: new Map(),
    tracks: new Map(),
    needsResume: ctx.state !== 'running',

    // Websocket
    wsurl: getProcessWs(),
  })
}

export const MainMachine = setup({
  types: {
    context: {} as TracksContext,
    events: {} as TracksEvents,
    children: {} as {
      wsMachine: 'WebsocketMachine',
      dlMachine: 'DownloadMachine'
    }
  },
  actions: {
    setTrackInfo: assign({
      tracksInfo: ({ context, event }) => {
        assertEvent(event, 'SEND_TRACK_INFO')
        return context.tracksInfo.set(event.id, event.track)
      }
    }),
    sendTrackInfo: sendTo('wsMachine', ({ context }: { context: TracksContext }) => ({
      type: 'WS.SEND_TRACKS',
      data: context.tracksOrder.map(toid => context.tracksInfo.get(toid))
    })),
    reorderTracks: assign({
      tracksOrder: ({ event }) => {
        assertEvent(event, 'REORDER_TRACKS')
        return event.tracksOrder || []
      }
    }),
    removeTrack: assign({
      tracksOrder: ({ context, event }) => {
        assertEvent(event, 'REMOVE_TRACK')
        stopChild(event.id)
        context.tracks.delete(event.id)
        context.tracksInfo.delete(event.id)
        return context.tracksOrder.filter((id: string) => id !== event.id)
      }
    }),
    tryResume: sendTo('persistTracks', ({ context }) => ({ type: 'TRY_RESUME', ctx: context.ctx })),
    setNeedsResume: assign({
      needsResume: ({ context }: { context: TracksContext }) => context.ctx.state !== 'running'
    }),
    createMic: assign(({ context, event, spawn }) => {
      assertEvent(event, 'ADD_MIC')
      const id = generateId(16)
      const input = createMicContext(id, context.ctx, context.local.gain, context.bcst.gain)
      return {
        tracksOrder: context.tracksOrder.concat([id]),
        tracksInfo: new Map(context.tracksInfo.set(id, { id, kind: 'mic', vol: 0, playing: false })),
        tracks: new Map(context.tracks.set(id, {
          kind: 'mic',
          big: false,
          service: spawn('micMachine', { id, input })
        }))
      }
    }),
    createPlayer: assign(({ context, spawn }) => {
      const id = generateId(16)
      const input = createPlayerInput(id, context.ctx, context.local.gain, context.bcst.gain)
      return {
        tracksOrder: context.tracksOrder.concat([id]),
        tracksInfo: new Map(context.tracksInfo.set(id, { id, kind: 'player', vol: 0, playing: false })),
        tracks: new Map(context.tracks.set(id, {
          kind: 'player',
          big: false,
          service: spawn('playerMachine', { id, input })
        }))
      }
    }),
    createOsc: assign(({ context, spawn }) => {
      const id = generateId(16)
      const input = createOscContext(id, context.ctx, context.local.gain, context.bcst.gain, 440)
      return {
        tracksOrder: context.tracksOrder.concat([id]),
        tracksInfo: new Map(context.tracksInfo.set(id, { id, kind: 'webaudio', vol: 0, playing: false })),
        tracks: new Map(context.tracks.set(id, {
          kind: 'webaudio',
          big: false,
          service: spawn('oscMachine', { id, input })
        }))
      }
    }),
    toggleBig: assign(({ context, event }) => {
      assertEvent(event, 'TOGGLE_BIG')
      const id = event.id
      const tmi = context.tracks.get(id)
      const tracks = new Map(context.tracks.set(id, Object.assign({}, tmi, { big: !tmi?.big })))
      return {
        big: Array.from(tracks.values()).find(tmi => tmi.big === true) ? true : false,
        tracks
      }
    }),
    cancelPersist: cancel('persist'),
    debouncePersist: sendTo('persistTracks', ({ context }: { context: TracksContext }) => ({
      type: 'PERSIST_TRACKS',
      data: context.tracksOrder.map(toid => context.tracksInfo.get(toid))
    }),
      { delay: 500, id: 'persist' }
    ),
    loadTracks: assign(({ context, event, spawn }) => {
      assertEvent(event, 'LOAD_TRACKS')
      const spawnTrack = (t: TrackInfo) => {
        if (t.kind === 'mic') {
          return spawn('micMachine', {
            id: t.id,
            input: createMicContext(t.id, context.ctx, context.local.gain, context.bcst.gain, t.deviceId)
          })
        }
        if (t.kind === 'player') {
          return spawn('playerMachine', {
            id: t.id,
            input: createPlayerInput(t.id, context.ctx, context.local.gain, context.bcst.gain, t.playlistId)
          })
        }
        // if (t.kind === 'webaudio') { return spawn(createOscMachine(t.id, context.ctx, context.local.gain, context.bcst.gain, 220), t.id) }
        // return spawn(createPlayerMachine(t.id, context.ctx, context.local.gain, context.bcst.gain, t.playlistId), t.id)
        return spawn('oscMachine', {
          id: t.id,
          input: createOscContext(t.id, context.ctx, context.local.gain, context.bcst.gain, 440)
        })
      }
      return {
        tracksOrder: event.tracks ? event.tracks.map(t => t.id) : [],
        tracksInfo: event.tracks ? event.tracks.reduce((acc, t) => acc.set(t.id, t), new Map()) : new Map(),
        tracks: event.tracks ? event.tracks.reduce((acc, t) => acc.set(t.id, { kind: t.kind, big: false, service: spawnTrack(t) }), new Map()) : new Map()
      }
    }),
    moveMedia: enqueueActions(({ event, enqueue }) => {
      assertEvent(event, 'MOVE_MEDIA')
      // function for moving media between player playlists
      if (!(event.dragInfo.trackId)) {
        return
      }
      if (event.dragInfo.trackId === event.overInfo.trackId || !event.overInfo.trackId) {
        return enqueue.sendTo(event.dragInfo.trackId, event)
      }
      // otherwise, we are moving media from one playlist to another
      // remove from the active and add to the over
      if (event.dragInfo.trackId && event.dragInfo.plItemId && event.dragInfo.media && event.overInfo.trackId) {
        enqueue.sendTo(event.dragInfo.trackId, { type: 'PLAYLIST_REMOVE', id: event.dragInfo.plItemId })
        enqueue.sendTo(event.overInfo.trackId, Object.assign(event, { type: 'PLAYLIST_CONCAT' }))
      }
    })
  },
  actors: {
    oscMachine,
    micMachine,
    playerMachine,
    WebsocketMachine,
    DownloadMachine,
    persistTracks: fromCallback(({ receive, sendBack }) => {
      receive((event: PersistTracks | TryResume) => {
        if (event.type === 'TRY_RESUME' && event.ctx.state !== 'running') {
          event.ctx.resume()
            .then(() => {
              sendBack({ type: 'SET_NEEDS_RESUME' })
            })
            .catch(e => console.log('error on resume', e))
        }
        if (event.type === 'PERSIST_TRACKS') {
          window.localStorage.setItem('tracksInfo', JSON.stringify(event.data))
        }
      })
      return () => null
    })
  }
}).createMachine({
  initial: 'idle',
  context: initContext,
  states: {
    idle: {
      invoke: [{
        src: 'WebsocketMachine',
        id: 'wsMachine',
        input: ({ context: { bcst } }: { context: TracksContext }) => ({
          chat: [],
          retryDuration: 500,
          wsurl: 'wss://nyc.listen.center/janode',
          remoteMixers: new Map(),
          localStream: bcst.dest.stream
        }),
      }, {
        src: 'persistTracks',
        id: 'persistTracks'
      }, {
        src: 'DownloadMachine',
        id: 'dlMachine'
      }
      ]
    }
  },
  on: {
    RESUME: { actions: 'tryResume', },
    LOAD_TRACKS: { actions: 'loadTracks' },
    REMOVE_TRACK: { actions: ['removeTrack', 'sendTrackInfo', 'cancelPersist', 'debouncePersist'] },
    REORDER_TRACKS: { actions: ['reorderTracks', 'sendTrackInfo', 'cancelPersist', 'debouncePersist'] },
    ADD_PLAYER: { actions: ['createPlayer', 'sendTrackInfo', 'cancelPersist', 'debouncePersist'] },
    ADD_MIC: { actions: ['createMic', 'sendTrackInfo', 'cancelPersist', 'debouncePersist'] },
    SET_NEEDS_RESUME: { actions: 'setNeedsResume' },
    ADD_OSC: { actions: ['createOsc', 'sendTrackInfo', 'cancelPersist', 'debouncePersist'] },
    TOGGLE_BIG: { actions: ['tryResume', 'toggleBig'] },
    'RTC.SET_REMOTE_STREAM': { actions: setRemoteStreamSource },
    SET_REMOTE_GAIN: {
      actions: ({ context, event }: { context: TracksContext, event: SetRemoteGain }) => {
        context.remote.gain.gain.linearRampToValueAtTime(event.vol, context.ctx.currentTime + 0.2)
        return []
      }
    },
    // we need to connect to a realy <audio/> element in the DOM
    // otherwise, Chrome has issues.  It is realated to echo cancellation
    // https://bugs.chromium.org/p/chromium/issues/detail?id=121673
    // SET_REMOTE_AUDIO: { actions: 'setRemoteStreamAudio' },
    SEND_TRACK_INFO: {
      actions: [
        'setTrackInfo',
        'sendTrackInfo',
        'cancelPersist',
        'debouncePersist'
      ]
    },
    MOVE_MEDIA: {
      actions: 'moveMedia'
    },
    ANNOUNCE_DL_KEYS: {
      actions: ({ context, event }: { context: TracksContext, event: AnnounceDlKeys }) => {
        return Array.from(context.tracks, ([_key, value]) => value)
          .filter(t => t.kind === 'player')
          .map(tmi => sendTo(tmi.service, event))
      }
    }
  }
})


export type MainService = ActorRefFrom<typeof MainMachine>
